@import "app/styles/variables.scss";

.pageContainer {
	padding: 30vh 20px 0 50px;
	min-height: 100vh;
}

.bodyContainer {
	color: $dashboardGray1;
	font-family: $publicFontFamily;
	font-size: 21px;
	line-height: 28px;

	h1 {
		font-size: 80px;
		line-height: 1.1em;
	}

	p {
		padding-top: 15px;
		max-width: 515px;
	}

	a {
		color: $dashboardGray1;
		text-decoration: underline;
	}

	a:hover {
		color: $dashboardGray2;
		text-decoration: none;
	}

	.linkContainer {
		color: $dashboardGray1;
		padding-top: 29px;
		padding-bottom: 60px;

		a {
			color: $dashboardGray1;
			text-decoration: underline;
		}

		a:hover {
			color: $dashboardGray2;
			text-decoration: none;
		}
	}
}

@media screen and (max-width: 1150px) {
	.bodyContainer {
		h1 {
			font-size: 55px;
		}
	}
}

@media screen and (max-width: 800px) {
	.bodyContainer {
		font-size: 18px;
		line-height: 24px;

		h1 {
			font-size: 40px;
		}
	}
}

@media screen and (max-width: 400px) {
	.pageContainer {
		padding-left: 20px;
	}

	.bodyContainer {
		font-size: 16px;
		line-height: 22px;

		h1 {
			font-size: 35px;
		}

		.linkContainer {
			padding-bottom: 40px;
		}
	}
}