$light1: #fcfcfe;
$light2: #f7f7f7;
$dark: #2c303b;
$black: #282727;
$black2: #3a3a3a;
$black3: #717171;
$black4: #a5a5a5;
$black5: #e5e5e4;
$black6: #e6e6e6;
$black7: #f1f1f1;
$black8: #f7f7f7;

$red: #d50057;

$orange: #ff4438;

$purple: #9d1d64;

$brown: #a79f88;

$error: #cb380b;

$red: #f22b68;

$brandingTeal: #38a6a6;
$brandingGreen: #0dab76;
$brandingDarkGreen: #587b7f;

$text1: #302a2c;

$blue: #27588d;
$lightBlue: #029cdb;

$marketsOverlayBlue: #9EA1DB;

$dashboardPurple: #a11964;
$dashboardPurpleLight: #a31264;
$dashboardOrange: #ff6b00;
$dashboardMapListingOrange: #ff412e;
$dashboardTextAndBorderGray: #707070;
$dashboardGrayLight: #d6d6d6;

$projectPlannerDarkGray: #97999b;
$resolveContainerMaxWidthPx: 1630px;
$projectPlannerSidePaddingPx: 40px;
$projectPlannerPropertyBlue: #48a1da;

$publicPurple: #ca005d;
$publicPurpleDark: #a11964;
$publicOrange: #ff412e;
$publicGray: #707070;
$publicShellMaxWidthPx: 1920px;
$formShellMaxWidthPx: 1630px;
// New public colors
$publicDarkPinkColor: #aa0061;
$publicPink: #ce0f69;
$publicOrangePanteon: #e4002b;
$publicDarkPurple: #6e4180;
$publicDarkGray: #58595c;
$publicLightGray: #e8e8ed;
$publicPanteonLightGrey: #f3f3f6;
$publicLightSilver: #d8d8d8;
$publicGhost: #cdcdd2;
$publicDimGray: #656565;
$publicShuttleGray: #62666a;
$publicAliceBlue: #EBF5FF;
$colocationColor: #aa0061;
$developmentColor: #e4002b;
$enterpriseColor: #6e4180;

$knightFrankRed: #D1274A;
$knightFrankRed2: #D0103A;
$knightFrankGray: #7f7f7f;
$knightFrankDarkGreen: #215050;
$knightFrankDarkGray: #58595C;
$knightFrankDarkBrown: #575758;
$knightFrankLightBrown: #DACEBF;
$knightFrankLightBrown2: rgba(218, 206, 191, 0.3);
$knightFrankMilkBrown: #F4F0EB;
$knightFrankGreen: #015151;

// Dashboard redesign palette - the higher the number the lighter the color
$dashboardPink1: #AA0061;
$dashboardPink2: #CE0F69;
$dashboardRed1: #C8102E;
$dashboardRed2: #E4002B;
$dashboardRed3: #F0142F;
$dashboardPurple1: #3F2A56;
$dashboardPurple2: #6E4180;
$dashboardOrange1: #F98E2B;
$dashboardYellow1: #FFD700;
$dashboardGreen1: #00843D;
$dashboardGreen2: #00B140;
$dashboardGreen3: #97D700;
$dashboardBlue1: #10069F;
$dashboardBlue2: #0077C8;
$dashboardBlue3: #00A3E0;
$dashboardBlue4: #1D96E2;
$dashboardGray1: #58595C;
$dashboardGray2: #97999B;
$dashboardGray3: #BBBCBC;
$dashboardGray4: #D0D0CE;
$dashboardGray5: #E8E8ED;
$dashboardGray6: #F3F3F6;
$dashboardGray7: #F2F2F6;
$dashboardGray8: #58595CCC;
$dashboardGray9: #F1F2F3;
$dashboardBlack: #1C1D1F;


$analyticsFontFamily: "Roboto", sans-serif;
$projectPlannerFontFamily: "Lato", sans-serif;
$publicFontFamily: "Lato", sans-serif;
$knightFrankFontFamily: "Lato", sans-serif;
$dashboardFontFamily: "Lato", sans-serif;

:export {
	colocationColor: $colocationColor;
	developmentColor: $developmentColor;
	enterpriseColor: $enterpriseColor;
}

/* START COLOURS */
//primary colours
$primary-pink: #AA0061;
$primary-pink-light: #CE0F69;
$primary-red: #E4002B;
$primary-purple: #6E4180;

//neutrals
$white: #fff;
$grey-lightest: #F3F3F6;
$grey-light: #E8E8ED;
$grey-med: #CCCCD2;
$grey-dark: #58595C;
$black: #1C1D1F;

//status colours
$success: #51A351;
$danger: #BD362F;
$warning: #F89406;
$info: #2F96B4;

//graph colours
$graph1: #6E4180;
$graph2: #CCCCD2;
$graph3: #AA0061;
$graph4: #F63C79;
$graph5: #3F2A56;
$graph6: #00B040;
$graph7: #99E951;
$graph8: #768592;
$graph9: #9EADB3;
$graph10: #E4002B;
$graph11: #FA94B5;
$graph12: #FDD0DF;
$graph13: #947ECE;
$graph14: #C1B5E2;
$graph15: #E0DAF0;
$graph16: #71335D;
$graph17: #B14481;
$graph18: #CE7DB3;
$graph19: #E1B4D2;
$graph20: #428300;
$graph21: #89C836;
$graph22: #D7E275;
$graph23: #004797;
$graph24: #296EC9;
$graph25: #1D96E2;
$graph26: #9ACBFF;
$graph27: #C9E6FD;
$graph28: #8E2920;
$graph29: #AF3E35;
$graph30: #E1635A;
$graph31: #E79F9C;
$graph32: #FDCDCA;
$graph33: #4E362B;
$graph34: #744B39;
$graph35: #99634B;
$graph36: #B08877;
$graph37: #D2BAAF;
$graph38: #425462;
$graph39: #E8E8ED;
$graph40: #F3F3F6;

/* END COLOURS */

/* START SPACING & SIZING */
$xxxs: 4px;
$xxs: 8px;
$xs: 12px;
$sm: 16px;
$md: 24px;
$lg: 32px;
$xl: 48px;
$xxl: 64px;
$xxxl: 72px;
/* END SPACING & SIZING */
